




















































































































































































































































































































































































































import Vue, { VueConstructor } from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '@/types';
import StatusTag from '@/components/media/CommissionStatusTag.vue';

interface VuexBindings {
  account: ApiResource.Account,
  detect: (idOrVanity: string) => ApiResource.Business,
  find: (b: string, c: string) => ApiResource.Commission,
  findCustomer: (u: string) => ApiResource.User | undefined,
  findFile: (c: string) => ApiResource.CommissionFile | undefined,
  findItem: (c: string) => ApiResource.CommissionItem | undefined,
  findMessage: (c: string) => ApiResource.CommissionMessage | undefined,
  list: (c: string) => ApiResource.Event[],
}

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  components: {
    FileDeleteButton: () => import(/* webpackChunkName: "c-f-rm" */ '@/components/commission/FileDeleteButton.vue'),
    FileDownloadButton: () => import(/* webpackChunkName: "c-f-dl" */ '@/components/commission/FileDownloadButton.vue'),
    MediaCommissionFile: () => import(/* webpackChunkName: "md-c-f" */ '@/components/media/CommissionFile.vue'),
    MediaCommissionItem: () => import(/* webpackChunkName: "md-c-i" */ '@/components/media/CommissionItem.vue'),
    MessageForm: () => import(/* webpackChunkName: "f-c-msg" */'@/components/commission/MessageForm.vue'),
    StatusTag,
  },
  data() {
    return {
      error: null as Error|null,
      loading: false,
    };
  },
  computed: {
    COMMISSION_ID(): string {
      return this.$route.params.COMMISSION_ID;
    },
    VANITY(): string {
      return this.$route.params.VANITY;
    },
    ...mapGetters({
      account: 'account/Get',
      detect: 'business/Detect',
      find: 'business/commission/Find',
      findCustomer: 'business/commission/FindCustomer',
      findFile: 'business/commission/file/Find',
      findItem: 'business/commission/item/Find',
      findMessage: 'business/commission/messages/Find',
      list: 'business/commission/event/List',
    }),
    business(): ApiResource.Business {
      // Fallback to finding by ID automatically
      return this.detect(this.VANITY);
    },
    commission(): ApiResource.Commission {
      return this.find(this.business.id, this.COMMISSION_ID);
    },
    customer(): ApiResource.User | undefined {
      return this.findCustomer(this.commission.customer_id);
    },
    events(): ApiResource.Event[] {
      return this.list(this.COMMISSION_ID)
        .filter((e: ApiResource.Event) => ![
          'commission.file.created',
          'commission.file.downloaded',
        ].includes(e.type))
        .sort((a: ApiResource.Event, b: ApiResource.Event) => {
          if (a.created_at > b.created_at) return 1;
          if (a.created_at < b.created_at) return -1;
          return 0;
        });
    },
  },
  methods: {
    handleError(e: Error) {
      this.error = e;
    },
    getSvgComponentNameForEventType(type: string): string {
      let component;

      switch (type) {
        case 'commission.waitlisted':
          component = 'Inbox';
          break;

        case 'commission.requested':
          component = 'Star';
          break;

        case 'commission.request.accepted':
          component = 'Check';
          break;

        case 'commission.request.rejected':
        case 'commission.status.disapproved_completion':
          component = 'XCircle';
          break;

        case 'commission.item.created':
          component = 'PlusCircle';
          break;

        case 'commission.item.refunded':
          component = 'Lock';
          break;

        case 'commission.item.paid':
          component = 'Unlock';
          break;

        case 'commission.file.created':
          component = 'FilePlus';
          break;

        case 'commission.file.uploaded':
          component = 'UploadCloud';
          break;

        case 'commission.file.deleted':
          component = 'FileMinus';
          break;

        case 'commission.message.created':
          component = 'MessageCircle';
          break;

        case 'commission.status.complete':
        case 'commission.status.finished':
          component = 'CheckCircle';
          break;

        case 'commission.status.active':
        case 'commission.status.pending_customer_action':
        default:
          component = 'InfoCircle';
          break;
      }

      return `Svg${component}`;
    },
    isEventAutomatic(event: ApiResource.Event) {
      return event.additional_data?.automatic === true;
    },
    async setStatusActive() {
      this.loading = true;

      await this.$store.dispatch('business/commission/SetActive', {
        BUSINESS_ID: this.business.id,
        COMMISSION_ID: this.commission.id,
      }).catch(this.handleError);

      this.loading = false;
    },
    async setStatusComplete() {
      this.loading = true;

      await this.$store.dispatch('business/commission/SetComplete', {
        BUSINESS_ID: this.business.id,
        COMMISSION_ID: this.commission.id,
      }).catch(this.handleError);

      this.loading = false;

      this.$a.goal(this.$a.goals.CommissionComplete);
    },
    async setStatusPending() {
      this.loading = true;

      await this.$store.dispatch('business/commission/SetPending', {
        BUSINESS_ID: this.business.id,
        COMMISSION_ID: this.commission.id,
      }).catch(this.handleError);

      this.loading = false;
    },
  },
});
